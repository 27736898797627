import * as React from 'react'
import Paper from '@mui/material/Paper'
import { GridColDef } from '@mui/x-data-grid'
import DialogBox from '../DialogBox/Pop'
import { DataGridTable } from './dataTable.styles'
import UpdateDetailPop from '../../pages/Listing/components/UpdateDetails/Update-Detail-Pop'

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Sr.No.',
    width: 80,
    resizable: false,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'billNo',
    headerName: 'Bill No.',
    width: 100,
    resizable: false,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'billDate',
    headerName: 'Bill Date',
    width: 100,
    resizable: false,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'customerName',
    headerName: 'Customer Name',
    width: 150,
    resizable: false,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'jobCard',
    headerName: 'Job Card No.',
    width: 150,
    resizable: false,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'registrationNo',
    headerName: 'Registration No.',
    width: 150,
    resizable: false,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'vehicleSaleDate',
    headerName: 'Vehicle Sale Date',
    width: 150,
    resizable: false,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'customerId',
    headerName: 'Customer ID',
    width: 150,
    resizable: false,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'partyName',
    headerName: 'Party Name',
    width: 150,
    resizable: false,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'gstinNo',
    headerName: 'GSTIN No.',
    width: 180,
    resizable: false,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'placeOfSupply',
    headerName: 'Place Of Supply',
    width: 150,
    resizable: false,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'partBasic',
    headerName: 'Part Basic',
    width: 150,
    resizable: false,
    headerClassName: 'super-app-theme--header',
  },
]

const rows = [
  {
    id: 1,
    billNo: '04-Apr-22',
    billDate: '04-Apr-22',
    customerName: 'Abeerendra singh',
    jobCard: 'Vitara Brezza',
    registrationNo: 'Mahindra',
    vehicleSaleDate: '01-Apr-2023',
    customerId: '5/VSL/29000006',
    partyName: 'Vainkateshay',
    gstinNo: 'GSTINN186320896389',
  },
  {
    id: 2,
    billNo: '04-Apr-22',
    billDate: '04-Apr-22',
    customerName: 'zbeerendra singh',
    jobCard: 'Vitara Brezza',
    registrationNo: 'Mahindra',
    vehicleSaleDate: '01-Apr-2023',
    customerId: '5/VSL/29000006',
    partyName: 'Vainkateshay',
    gstinNo: 'GSTINN186320896389',
  },
  {
    id: 3,
    billNo: '04-Apr-22',
    billDate: '04-Apr-22',
    customerName: 'Tbeerendra singh',
    jobCard: 'Vitara Brezza',
    registrationNo: 'Mahindra',
    vehicleSaleDate: '01-Apr-2023',
    customerId: '5/VSL/29000006',
    partyName: 'Vainkateshay',
    gstinNo: 'GSTINN186320896389',
  },
  {
    id: 4,
    billNo: '04-Apr-22',
    billDate: '04-Apr-22',
    customerName: 'Nbeerendra singh',
    jobCard: 'Vitara Brezza',
    registrationNo: 'Mahindra',
    vehicleSaleDate: '01-Apr-2023',
    customerId: '5/VSL/29000006',
    partyName: 'Vainkateshay',
    gstinNo: 'GSTINN186320896389',
  },
  {
    id: 5,
    billNo: '04-Apr-22',
    billDate: '04-Apr-22',
    customerName: 'Lbeerendra singh',
    jobCard: 'Vitara Brezza',
    registrationNo: 'Mahindra',
    vehicleSaleDate: '01-Apr-2023',
    customerId: '5/VSL/29000006',
    partyName: 'Vainkateshay',
    gstinNo: 'GSTINN186320896389',
  },
  {
    id: 6,
    billNo: '04-Apr-22',
    billDate: '04-Apr-22',
    customerName: 'Pbeerendra singh',
    jobCard: 'Vitara Brezza',
    registrationNo: 'Mahindra',
    vehicleSaleDate: '01-Apr-2023',
    customerId: '5/VSL/29000006',
    partyName: 'Vainkateshay',
    gstinNo: 'GSTINN186320896389',
  },
  {
    id: 7,
    billNo: '04-Apr-22',
    billDate: '04-Apr-22',
    customerName: 'Bbeerendra singh',
    jobCard: 'Vitara Brezza',
    registrationNo: 'Mahindra',
    vehicleSaleDate: '01-Apr-2023',
    customerId: '5/VSL/29000006',
    partyName: 'Vainkateshay',
    gstinNo: 'GSTINN186320896389',
  },
  {
    id: 8,
    billNo: '04-Apr-22',
    billDate: '04-Apr-22',
    customerName: 'Obeerendra singh',
    jobCard: 'Vitara Brezza',
    registrationNo: 'Mahindra',
    vehicleSaleDate: '01-Apr-2023',
    customerId: '5/VSL/29000006',
    partyName: 'Vainkateshay',
    gstinNo: 'GSTINN186320896389',
  },
  {
    id: 9,
    billNo: '04-Apr-22',
    billDate: '04-Apr-22',
    customerName: 'Gbeerendra singh',
    jobCard: 'Vitara Brezza',
    registrationNo: 'Mahindra',
    vehicleSaleDate: '01-Apr-2023',
    customerId: '5/VSL/29000006',
    partyName: 'Vainkateshay',
    gstinNo: 'GSTINN186320896389',
  },
  {
    id: 10,
    billNo: '04-Apr-22',
    billDate: '04-Apr-22',
    customerName: 'beerendra singh',
    jobCard: 'Vitara Brezza',
    registrationNo: 'Mahindra',
    vehicleSaleDate: '01-Apr-2023',
    customerId: '5/VSL/29000006',
    partyName: 'Vainkateshay',
    gstinNo: 'GSTINN186320896389',
  },
]

const paginationModel = { page: 0, pageSize: 10 }

export default function DataTable() {
  const [open, setOpen] = React.useState(false)
  const [dialogTitle, setDialogTitle] = React.useState('Update Details')
  const [dialogWidth, setDialogWidth] = React.useState('1000px')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setDialogTitle('Update Details')
    setDialogWidth('1000px')
    setOpen(false)
  }

  return (
    <Paper>
      <DataGridTable
        rows={rows}
        rowHeight={50}
        columns={columns}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[10, 25, 75, 100]}
        disableColumnMenu
        onRowClick={handleClickOpen}
        density="compact"
      />
      <DialogBox open={open} onClose={handleClose} title={dialogTitle} width={dialogWidth}>
        <UpdateDetailPop />
      </DialogBox>
    </Paper>
  )
}
