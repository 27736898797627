import { Dialog, DialogTitle, IconButton, styled } from '@mui/material'
import { variables } from '../../styles/theme'

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))
export const DialogTitleTop = styled(DialogTitle)({
  backgroundColor: `${variables.primaryColor}`,
  color: `${variables.bgColor}`,
  fontSize: '1rem',
  padding: '0.5rem 1rem',
})
export const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: '2px',
  right: '8px',
  color: `${variables.bgColor}`,
})
