import { DataGrid } from '@mui/x-data-grid'
import styled from 'styled-components'
import { variables } from '../../styles/theme'

export const DataGridTable = styled(DataGrid)({
  border: 0,
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '&.MuiDataGrid-root  .MuiDataGrid-cell:focus': {
    outline: '0',
  },
  '&.MuiDataGrid-root  .MuiDataGrid-columnHeader:focus': {
    outline: '0',
  },
  '& .super-app-theme--header': {
    backgroundColor: `${variables.primaryColor}`,
    color: `${variables.bgColor}`,
    height: '50px !important',
  },
  '& .MuiDataGrid-sortIcon': {
    opacity: 1,
    color: `${variables.bgColor}`,
  },
  '& .MuiDataGrid-cell': {
    color: '#595353',
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: '#f1f2f6',
  },
  '& .MuiDataGrid-row:nth-child(even)': {
    backgroundColor: '#f1f2f6',
  },
  '& .MuiDataGrid-row:nth-child(odd)': {
    backgroundColor: '#f9f9fb',
  },
})
