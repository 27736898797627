import styled from 'styled-components'
import FlexBox from '../../../../components/FlexBox'
import { TextareaAutosize } from '@mui/material'
import CustomButton from '../../../../components/button/CommonButton'

export const SelectBoxDiv = styled(FlexBox)({
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '10px',
  gap: '10px',
})

export const Textarea = styled(TextareaAutosize)({
  width: '75%',
  borderRadius: '5px',
  padding: '10px',
  outline: 'none',
})

export const SubmitButton = styled(CustomButton)({
  padding: '5px 40px !important',
  borderRadius: '0px !important',
})
