import { Autocomplete, InputLabel, styled } from '@mui/material'
import { variables } from '../../styles/theme'

export const SelectDataBox = styled(Autocomplete)({
  '&.MuiOutlinedInput-root': {
    padding: '4px',
  },
})

export const SelectDataBoxLabel = styled(InputLabel)({
  color: variables.color,
})
