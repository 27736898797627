/* eslint-disable prettier/prettier */
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { variables } from 'styles/theme'
import Typography from '../../components/Typography'

export const ForgotPasswordMain = styled(Box)({
  background: `${variables.primaryColor}`,
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
})
export const ForgotSection = styled(Box)({
  background: `${variables.bgColor}`,
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  width: '35%',
  height: '60vh',
  position: 'relative',
})
export const Tittle = styled(Typography.H3)({
  color: `${variables.primaryColor}`,
  marginTop: '5px',
  marginBottom: '0px',
})
export const SubTittle = styled(Typography.P3)({
  color: `${variables.primaryColor}`,
  marginTop: '5px',
  marginBottom: '0px',
})
export const BackButton = styled(Box)({
  position: 'absolute',
  top: '10px',
  left: '10px',
  padding: '6px',
  cursor: 'pointer',
  '&:hover': {
    background: '#292f8747',
    borderRadius: '100%'
  }
})
