import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import logo from 'assets/maruti-logo.svg'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { Link, useNavigate } from 'react-router-dom'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { AppBar, Drawer, DrawerHeader, HeaderBox, HeaderLogo, SidebarProps } from './sidebar.styles'

const getIcon = (text: string) => {
  switch (text) {
    case 'Dashboard':
      return <HomeOutlinedIcon sx={{ color: 'white' }} />
    case 'Listing':
      return <InboxIcon sx={{ color: 'white' }} />

    default:
      return null
  }
}

const Sidebar: React.FC<SidebarProps> = ({ children }) => {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleLogout = () => {
    navigate('/')
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ backgroundColor: 'white' }}>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                marginRight: 5,
              },
              open && { display: 'none' },
            ]}
          >
            <ChevronRightIcon />
          </IconButton>
          <HeaderBox>
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', fontSize: '16px', color: 'black' }}
              noWrap
              component="div"
            >
              Common SSO
            </Typography>
            <HeaderLogo>
              <img src={logo} alt="" width={180} />
            </HeaderLogo>
          </HeaderBox>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} color="inherit">
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <List>
          {['Dashboard', 'Listing'].map(text => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <Link to={`/${text.toLowerCase()}`} style={{ color: 'white', textDecoration: 'none' }}>
                <ListItemButton
                  sx={[
                    {
                      minHeight: 48,
                      px: 2.5,
                    },
                    open
                      ? {
                          justifyContent: 'initial',
                        }
                      : {
                          justifyContent: 'center',
                        },
                  ]}
                >
                  <ListItemIcon
                    sx={[
                      {
                        minWidth: 0,
                        justifyContent: 'center',
                      },
                      open
                        ? {
                            mr: 3,
                          }
                        : {
                            mr: 'auto',
                          },
                    ]}
                  >
                    {getIcon(text)}
                  </ListItemIcon>

                  <ListItemText
                    primary={text}
                    sx={[
                      open
                        ? {
                            opacity: 1,
                          }
                        : {
                            opacity: 0,
                          },
                    ]}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
        <List sx={{ mt: 'auto', padding: 0 }}>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 30,
                px: 2.5,
                justifyContent: 'center',
                bgcolor: '#252b8b',
                color: 'white',
              }}
              onClick={handleLogout}
            >
              <ListItemIcon
                sx={[
                  {
                    minWidth: 0,
                    justifyContent: 'center',
                  },
                  open
                    ? {
                        mr: 3,
                      }
                    : {
                        mr: 'auto',
                      },
                ]}
              >
                <ExitToAppIcon sx={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                sx={[
                  open
                    ? {
                        opacity: 1,
                      }
                    : {
                        opacity: 0,
                      },
                ]}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main">
        <DrawerHeader />
        <Box sx={{ padding: '10px' }}>{children}</Box>
      </Box>
    </Box>
  )
}

export default Sidebar
