import { Autocomplete, TextField, AutocompleteProps } from '@mui/material'
import { variables } from '../../styles/theme'
import { useState } from 'react'
import { SelectDataBoxLabel } from './select.style'

interface CommonAutocompleteProps<T> extends Partial<AutocompleteProps<T, false, false, false>> {
  label: string
  options: T[]
  subLabel?: string
}

export default function SelectBox<T extends string>({
  label,
  options,
  subLabel,
  ...props
}: CommonAutocompleteProps<T>) {
  const [selectedValue, setSelectedValue] = useState<T | null>('Click to select' as T)

  const optionsWithDefault = ['Click to select', ...options] as T[]

  return (
    <div style={{ width: '100%' }}>
      <SelectDataBoxLabel shrink htmlFor="bootstrap-input">
        {label}
      </SelectDataBoxLabel>
      <Autocomplete
        size="small"
        disablePortal
        options={optionsWithDefault}
        value={selectedValue}
        onChange={(_, newValue) => setSelectedValue(newValue)}
        isOptionEqualToValue={(option, value) => option === value}
        getOptionDisabled={option => option === 'Click to select' && selectedValue !== 'Click to select'}
        getOptionLabel={option => option as string}
        {...props}
        sx={{
          '& label.Mui-focused': {
            color: `${variables.primaryColor}`,
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${variables.primaryColor}`,
          },
        }}
        renderInput={params => <TextField {...params} label={subLabel} />}
      />
    </div>
  )
}
