import { Box } from '@mui/material'
import Sidebar from 'components/Layout/sidebar'
import Typography from 'components/Typography'

const Dashboard = () => {
  return (
    <>
      <Sidebar>
        <Box sx={{ display: 'flex' }}>
          <Typography.H1>Dashboard</Typography.H1>
        </Box>
      </Sidebar>
    </>
  )
}

export default Dashboard
