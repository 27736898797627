import { ForgotPasswordMain, ForgotSection, BackButton, SubTittle, Tittle } from './forgotPassword.styles'
import forgoPassImage from 'assets/forgot-password.png'
import arrow from 'assets/arrow.png'
import Input from 'components/input/input'
import { FormProvider, useForm } from 'react-hook-form'
import { Box } from '@mui/material'
import CustomButton from 'components/button/CommonButton'
import { useNavigate } from 'react-router-dom'

const ForgotPasswordComponent = () => {
  const methods = useForm()
  const navigate = useNavigate()

  const onSubmit = (data: any) => {
    console.log(data)
  }
  return (
    <ForgotPasswordMain>
      <ForgotSection>
        <BackButton onClick={() => navigate(-1)}>
          <img width={'20px'} src={arrow} alt="arrow btn" />
        </BackButton>
        <img width={'60px'} src={forgoPassImage} alt="forgot password" />
        <Tittle>Forgot Password</Tittle>
        <SubTittle>Enter your email account to reset password</SubTittle>
        <FormProvider {...methods}>
          <form style={{ minWidth: '350px' }} onSubmit={methods.handleSubmit(onSubmit)}>
            <div style={{ width: '100%' }}>
              <Box padding={'15px 0 20px 0'}>
                <Input label="Email" name="email" type="email" />
              </Box>
              <CustomButton type="submit" fullWidth variant="contained">
                Continue
              </CustomButton>
            </div>
          </form>
        </FormProvider>
      </ForgotSection>
    </ForgotPasswordMain>
  )
}

export default ForgotPasswordComponent
