import * as React from 'react'
// import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
// import DialogActions from '@mui/material/DialogActions'
import CloseIcon from '@mui/icons-material/Close'
import { BootstrapDialog, CloseButton, DialogTitleTop } from './pop.style'
import { UpdateDialogBoxProps } from './types'

export default function DialogBox(Props: UpdateDialogBoxProps) {
  const { open, onClose, children, title, width } = Props
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          '& .MuiDialog-paper': {
            width: `${width}`,
            maxWidth: `${width}`,
          },
        }}
      >
        <DialogTitleTop id="customized-dialog-title">{title}</DialogTitleTop>
        <CloseButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <DialogContent dividers>{children}</DialogContent>
        {/* <DialogActions>
          <Button onClick={onClose}>Save changes</Button>
        </DialogActions> */}
      </BootstrapDialog>
    </React.Fragment>
  )
}
