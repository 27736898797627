import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { variables } from 'styles/theme'
import Typography from 'components/Typography'
import FlexBox from '../../components/FlexBox'

export const SingUpMain = styled(Box)({
  background: `${variables.primaryColor}`,
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
})
export const SingUpSection = styled(Box)({
  background: `${variables.bgColor}`,
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
})
export const SingUpForm = styled(Box)({
  component: 'form',
  width: '100%',
  maxWidth: 'auto',
  noValidate: true,
})
export const Tittle = styled(Typography.H2)({
  color: `${variables.primaryColor}`,
  marginTop: '5px',
  marginBottom: '0px',
})

export const FlexBoxInput = styled(FlexBox)({
  gap: '10px',
  margin: '10px 0',
})
