const FLEXBOX_PROPS = [
  'direction',
  'align',
  'justify',
  'flex',
  'gap',
  'rowGap',
  'colGap',
  'wrap',
  'responsive',
  'fullWidth',
  'halfWidth',
]
export const CUSTOM_PROPS_NOT_FOR_DOM = [...FLEXBOX_PROPS]
