import { Interpolation, RuleSet, css } from 'styled-components'
import { Styles } from 'styled-components/dist/types'

export const sizes = {
  desktop: 80, // lg 1280
  laptop: 68.25, // md 1092
  tablet: 48, // sm 768
  phone: 30, // xs 480
} as const

type MediaType = keyof typeof sizes | 'tabletOnly' | 'tabletAbove' | 'phoneAbove' | 'laptopAbove'

type MediaObj = {
  [key in MediaType]: (styles: Styles<object>, ...interpolations: Interpolation<object>[]) => RuleSet
}

// iterate through the sizes and create default media templates
let mediaObj = Object.keys(sizes).reduce((accumulator, label) => {
  // use rem in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const remSize = sizes[label as keyof typeof sizes]
  accumulator[label as MediaType] = (...args) => css`
    @media (max-width: ${remSize}rem) {
      ${css(...args)};
    }
  `
  return accumulator
}, {} as MediaObj)

mediaObj = {
  ...mediaObj,
  tabletOnly: (...args) => css`
    @media (min-width: ${sizes.tablet}rem) and (max-width: ${sizes.laptop}rem) {
      ${css(...args)};
    }
  `,
  tabletAbove: (...args) => css`
    @media (min-width: ${sizes.tablet}rem) {
      ${css(...args)};
    }
  `,
  phoneAbove: (...args) => css`
    @media (min-width: ${sizes.phone}rem) {
      ${css(...args)};
    }
  `,
  laptopAbove: (...args) => css`
    @media (min-width: ${sizes.laptop}rem) {
      ${css(...args)};
    }
  `,
}

/**
 * Media query helpers, can be used in 3 ways
 *
 * @example
 * media.phone`
 *   font-size: ${props => props.theme.fontSizes.p3};
 * `
 * media.laptopAbove({
 *   color: 'red',
 * })
 * media.desktop(props => ({
 *   background: props.theme.secondary,
 * }))
 * @returns {RuleSet}
 */
export const media = mediaObj
