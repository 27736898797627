import { Button, ButtonProps } from '@mui/material'
import styled from 'styled-components'
import { variables } from 'styles/theme'
import { BORDER_RADIUS } from 'styles/constants'

interface CustomButtonProps extends ButtonProps {
  bgColor?: string
  textColor?: string
  hoverBgColor?: string
  hoverTextColor?: string
  borderRadius?: string
  textTransform?: string
}

const CustomButton = styled(Button)<CustomButtonProps>`
  border-radius: ${BORDER_RADIUS.button};

  background-color: ${props => (props.bgColor ? props.bgColor : variables.primaryColor)} !important;

  color: ${props => (props.textColor ? props.textColor : variables.bgColor)} !important;

  text-transform: ${props => props.textTransform};
  &:hover {
    background-color: ${props =>
      props.hoverBgColor ? props.hoverBgColor : variables.secondaryColor} !important;
    color: ${props => (props.hoverTextColor ? props.hoverTextColor : variables.bgColor)} !important;
  }
`

export default CustomButton
