import * as React from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import SelectBox from '../../../../components/selectBox/selectBox'
import { SelectBoxDiv, SubmitButton, Textarea } from './updateDetail.styles'
import { variables } from '../../../../styles/theme'

const UpdateDetailPop = () => {
  const [value, setValue] = React.useState('1')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  return (
    <>
      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: 'transparent',
              },
              '& .MuiTab-root': {
                border: '2px solid ' + variables.primaryColor,
                color: variables.primaryColor,
                textTransform: 'none',
                padding: '8px 60px',
                minHeight: '35px',
                height: '35px',
                '&:nth-of-type(2)': {
                  borderLeft: 'none',
                  borderRight: 'none',
                },
                '&.Mui-selected': {
                  backgroundColor: variables.primaryColor,
                  color: variables.bgColor,
                },
              },
            }}
          >
            <Tab label="Invoice Details" value="1" />
            <Tab label="Payment Details" value="2" />
            <Tab label="Write Off Request" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <SelectBoxDiv>
            <SelectBox fullWidth subLabel="Select View" label="Bill No." options={['Bill No.1']} />
            <SelectBox fullWidth subLabel="Select Channel" label="Bill Date" options={[]} />
            <SelectBox fullWidth subLabel="Select Channel" label="Job Card No." options={[]} />
            <SelectBox fullWidth subLabel="Select Region" label="Registration No." options={[]} />
          </SelectBoxDiv>

          <SelectBoxDiv>
            <SelectBox fullWidth subLabel="Select Location" label="Vehicle Sale Date" options={[]} />
            <SelectBox fullWidth subLabel="Select MSPIN" label="Customer ID" options={[]} />
            <SelectBox fullWidth subLabel="Inter Customer ID" label="Party Name" options={[]} />
            <SelectBox fullWidth subLabel="Select Model" label="GSTIN No." options={[]} />
          </SelectBoxDiv>

          <SelectBoxDiv>
            <SelectBox fullWidth subLabel="Select Location" label="Place of Supply" options={[]} />
            <SelectBox fullWidth subLabel="Select MSPIN" label="Part Basic" options={[]} />
            <SelectBox fullWidth subLabel="Inter Customer ID" label="Part Discount" options={[]} />
            <SelectBox fullWidth subLabel="Select Model" label="Part Charges" options={[]} />
          </SelectBoxDiv>

          <SelectBoxDiv>
            <SelectBox fullWidth subLabel="Select Location" label="Labour Discount" options={[]} />
            <SelectBox fullWidth subLabel="Select MSPIN" label="Labour Charges" options={[]} />
            <SelectBox fullWidth subLabel="Inter Customer ID" label="Round off Amount" options={[]} />
            <SelectBox fullWidth subLabel="Select Model" label="Bill Amount" options={[]} />
          </SelectBoxDiv>

          <SelectBoxDiv>
            <SelectBox fullWidth subLabel="Select Location" label="Salvage" options={[]} />
            <SelectBox fullWidth subLabel="Select MSPIN" label="Deductant" options={[]} />
            <SelectBox fullWidth subLabel="Inter Customer ID" label="Total Amount Due" options={[]} />
            <div style={{ width: '100%' }}></div>
          </SelectBoxDiv>

          <SelectBoxDiv>
            <Textarea placeholder="Remarks:" />
            <SubmitButton>Submit</SubmitButton>
          </SelectBoxDiv>
        </TabPanel>

        <TabPanel value="2">Item Two</TabPanel>
        <TabPanel value="3">Item Three</TabPanel>
      </TabContext>
    </>
  )
}
export default UpdateDetailPop
