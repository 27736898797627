import styled, { css } from 'styled-components'

import { FONT_WEIGHTS, LINE_HEIGHTS } from 'styles/constants'
import { media } from 'utils/media'

type TypographyProps = {
  align?: string
  weight?: string
  color?: string
  variant?: string
  size?: string
  lineHeight?: string
  letterSpacing?: string
  textTransform?: string
  fontFamily?: string
}

const CommonStyles = css<TypographyProps>`
  text-align: ${props => props.align};
  font-weight: ${props => props.weight};
  color: ${({ color }) => color};
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.size};
  line-height: ${props => props.lineHeight};
  letter-spacing: ${props => props.letterSpacing};
  text-transform: ${props => props.textTransform};
`

const Typography = {
  H1: styled.h1`
    font-size: ${props => props.theme.fontSizes?.h1};
    font-weight: ${FONT_WEIGHTS.bold};
    font-family: ${props => props.theme.fontFamily?.heading || props.theme?.fontFamily?.body};
    line-height: ${LINE_HEIGHTS.h1};
    ${CommonStyles}
    ${media.phone`
      font-size: ${props => props.theme.fontSizes?.h3};
      line-height: ${LINE_HEIGHTS.h3};
    `}
  `,
  H2: styled.h2`
    font-size: ${props => props.theme.fontSizes?.h2};
    font-weight: ${FONT_WEIGHTS.bold};
    font-family: ${props => props.theme.fontFamily?.heading || props.theme?.fontFamily?.body};
    line-height: ${LINE_HEIGHTS.h2};
    ${CommonStyles}
    ${media.phone`
      font-size: ${props => props.theme.fontSizes?.p1};
      line-height: ${LINE_HEIGHTS.p1};
  `}
  `,
  H3SemiBold: styled.h3`
    font-size: ${props => props.theme.fontSizes?.h3};
    font-weight: ${FONT_WEIGHTS.semibold};
    font-family: ${props => props.theme.fontFamily?.bold || props.theme?.fontFamily?.body};
    line-height: ${LINE_HEIGHTS.h3};
    ${CommonStyles}
    ${media.phone`
      font-size: ${props => props.theme.fontSizes?.p1};
      line-height: ${LINE_HEIGHTS.p1};
    `}
  `,
  H3: styled.h3`
    font-size: ${props => props.theme.fontSizes?.h3};
    font-weight: ${FONT_WEIGHTS.bold};
    font-family: ${props => props.theme.fontFamily?.heading || props.theme?.fontFamily?.body};
    line-height: ${LINE_HEIGHTS.h3};
    ${CommonStyles}
    ${media.phone`
      font-size: ${props => props.theme.fontSizes?.p1};
      line-height: ${LINE_HEIGHTS.p1};
    `}
  `,
  P1: styled.p`
    font-size: ${props => props.theme.fontSizes?.p1};
    font-weight: 400;
    line-height: ${LINE_HEIGHTS.p1};
    ${CommonStyles}
    ${media.phone`
      font-size: ${props => props.theme.fontSizes?.p2};
      line-height: ${LINE_HEIGHTS.p2};
    `}
  `,
  P1Strong: styled.p`
    font-size: ${props => props.theme.fontSizes?.p1};
    font-weight: ${FONT_WEIGHTS.semibold};
    font-family: ${props => props.theme.fontFamily?.semiBold || props.theme?.fontFamily?.body};
    line-height: ${LINE_HEIGHTS.p1};
    ${CommonStyles}
    ${media.phone`
      font-size: ${props => props.theme.fontSizes?.p2};
      line-height: ${LINE_HEIGHTS.p2};
    `}
  `,
  P2: styled.p`
    font-size: ${props => props.theme.fontSizes?.p2};
    font-weight: 400;
    line-height: ${LINE_HEIGHTS.p2};
    ${CommonStyles}
    ${media.phone`
      font-size: ${props => props.theme.fontSizes?.p3};
      line-height: ${LINE_HEIGHTS.p3};
    `}
  `,
  P2Strong: styled.p`
    font-size: ${props => props.theme.fontSizes?.p2};
    font-weight: ${FONT_WEIGHTS.semibold};
    font-family: ${props => props.theme.fontFamily?.bold || props.theme?.fontFamily?.body};
    line-height: ${LINE_HEIGHTS.p2};
    ${CommonStyles}
    ${media.phone`
      font-size: ${props => props.theme.fontSizes?.p3};
      line-height: ${LINE_HEIGHTS.p3};
  `}
  `,
  P3: styled.p`
    font-size: ${props => props.theme.fontSizes?.p3};
    font-weight: 400;
    line-height: ${LINE_HEIGHTS.p3};
    ${CommonStyles}
    ${media.phone`
      font-size: ${props => props.theme.fontSizes?.p4};
      line-height: ${LINE_HEIGHTS.p4};
    `}
  `,
  P3Strong: styled.p`
    font-size: ${props => props.theme.fontSizes?.p3};
    font-weight: ${FONT_WEIGHTS.semibold};
    font-family: ${props => props.theme.fontFamily?.bold || props.theme?.fontFamily?.body};
    line-height: ${LINE_HEIGHTS.p3};
    ${CommonStyles}
    ${media.phone`
      font-size: ${props => props.theme.fontSizes?.p4};
      line-height: ${LINE_HEIGHTS.p4};
  `}
  `,
  P4: styled.p`
    font-size: ${props => props.theme.fontSizes?.p4};
    font-weight: 400;
    font-family: ${props => props.theme.fontFamily?.semiBold || props.theme?.fontFamily?.body};
    line-height: ${LINE_HEIGHTS.p4};
    ${CommonStyles}
    ${media.phone`
      font-size: ${props => props.theme.fontSizes?.p5};
      line-height: ${LINE_HEIGHTS.p5};
  `}
  `,
  P5: styled.p`
    font-size: ${props => props.theme.fontSizes?.p5};
    font-weight: 400;
    font-family: ${props => props.theme.fontFamily?.semiBold || props.theme?.fontFamily?.body};
    line-height: ${LINE_HEIGHTS.p5};
    ${CommonStyles}
  `,
  Div: styled.div`
    ${CommonStyles}
  `,
}

export default Typography
