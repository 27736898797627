export const COLORS = {
  black: '#000000',
  darkGray: '#8D8D8D',
  gray900: '#003459',
  gray800: '#3D4046',
  gray700: '#5D5D5D',
  gray600: '#8D8D8D',
  gray: '#A5A5A5',
  lightGray: '#E0E0E0',
  darkWhite: '#F5F5F5',
  white: '#FFFFFF',
  green: '#00C497',
  red: '#FF0000',
  gold: '#FFC106',
  // dark shades of lightgrey
  lightGray100: '#D6D6D6',
  lightGray200: '#CBCBCB',
} as const

export const FONT_WEIGHTS = {
  bold: 700,
  semibold: 600,
  medium: 500,
  normal: 400,
} as const

export const LINE_HEIGHTS = {
  h1: '4.25rem', // 68px
  h2: '2.625rem', // 42px
  h3: '2.375rem', // 38px
  p1: '1.6875rem', // 28px
  p2: '1.5625rem', // 25px
  p3: '1.3125rem', // 20px
  p4: '1.125rem', // 18px
  p5: '1rem', // 16px
  p6: '0.75rem', // 12px
} as const

export const BOX_SHADOW = {
  card: ' 0px 3px 6px #00000029',
  button: '0px 3px 6px #3D404614',
  footer: '0px 2px 6px 0px #21252929',
} as const

export const BORDER_RADIUS = {
  card: '0.75rem',
  button: '0.25rem',
  content: '0.5rem',
  icon: '100%',
  sm: '5px', // Button
  md: '8px', // Tables
  lg: '11px', // CARD_BORDER_RADIUS
  xl: '20px', // Round Badges
  xxl: '40px', // Round Badges
  round: '50%',
} as const

export const MAX_CONTENT_WIDTH = '66.688rem'

export const MAX_CARD_WIDTH = '50rem'

export const EMAIL_CARD_MAX_WIDTH = '53.125rem'
