import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import { API_URL } from '../constants/env'

const apiClient = axios.create({
  baseURL: API_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
})

const handleRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error('API Request Error:', error)
  return Promise.reject(error)
}

const handleResponseError = (error: AxiosError): Promise<AxiosError> => {
  if (error.response) {
    console.error('API Response Error:', error.response)
  } else {
    console.error('Network or other error', error)
  }
  return Promise.reject(error)
}

apiClient.interceptors.request.use(config => config, handleRequestError)
apiClient.interceptors.response.use(response => response, handleResponseError)

// Define a generic API service interface
interface ApiService {
  get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>
  post<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>
  put<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>
  delete<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>
}

// Implement the API service
const apiService: ApiService = {
  get: <T>(url: string, config?: AxiosRequestConfig) => apiClient.get<T>(url, config),
  post: <T>(url: string, data?: unknown, config?: AxiosRequestConfig) => apiClient.post<T>(url, data, config),
  put: <T>(url: string, data?: unknown, config?: AxiosRequestConfig) => apiClient.put<T>(url, data, config),
  delete: <T>(url: string, config?: AxiosRequestConfig) => apiClient.delete<T>(url, config),
}

export default apiService
