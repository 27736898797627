import Sidebar from 'components/Layout/sidebar'
import DataTable from '../../components/Data-table/data-table'

const Outstanding = () => {
  return (
    <>
      <Sidebar>
        <DataTable />
      </Sidebar>
    </>
  )
}

export default Outstanding
