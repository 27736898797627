import React, { useState } from 'react'
import { Box } from '@mui/material'
import navyGenLogo from '../../assets/powerdby-navygen.svg'
import {
  LoginForm,
  LoginMain,
  LoginSection,
  LoginTittle,
  Subtitle,
  WelcomeTitle,
  ErrorMessage,
  SignUP,
} from './login.styles'
import { useNavigate } from 'react-router-dom'
import CustomButton from '../../components/button/CommonButton'
import Input from '../../components/input/input'
import { FormProvider, useForm } from 'react-hook-form'
import FlexBox from '../../components/FlexBox'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import LoginInterface from './type'
import apiService from '../../utils/apiService'

const LoginSchema = object({
  dealerEmailID: string().required('User Name is required'),
  password: string().required('Password is required'),
})

const LoginComponent = () => {
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  console.log(errorMessage)

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      dealerEmailID: '',
      password: '',
    },
  })

  const loginSubmit = async (rep: LoginInterface) => {
    try {
      if (rep) {
        await apiService.post('/login', rep)
        navigate('/dashboard')
        setErrorMessage('')
      }
    } catch (err) {
      console.log(err.response.data.message)

      setErrorMessage(err.response.data.message || 'Incorrect username or password.')
    }
  }

  return (
    <LoginMain>
      <LoginSection>
        <Subtitle>Claims payments' reconciliation system</Subtitle>
        <LoginTittle>Login</LoginTittle>
        <WelcomeTitle>Welcome! to CPRS</WelcomeTitle>
        <FormProvider {...methods}>
          <form style={{ width: '280px' }} onSubmit={methods.handleSubmit(loginSubmit)}>
            <LoginForm>
              <Box padding={'15px 0 13px 0'}>
                <Input type="text" label="User Name" name="dealerEmailID" />
              </Box>
              <Box padding={'13px 0 13px 0'}>
                <Input type="password" label="Password" name="password" />
              </Box>
              <ErrorMessage>{errorMessage}</ErrorMessage>
              <CustomButton type="submit" fullWidth variant="contained">
                Login
              </CustomButton>
              <FlexBox justify="space-between">
                <Box>
                  <SignUP onClick={() => navigate('/sing-up')}>Sign Up</SignUP>
                </Box>
                <Box>
                  <SignUP onClick={() => navigate('/forgot-password')}>Forgot password?</SignUP>
                </Box>
              </FlexBox>
            </LoginForm>
          </form>
        </FormProvider>

        <Box component="div" mt={5} marginLeft={'100px'} textAlign={'end'}>
          <img src={navyGenLogo} alt="Navy-GEN" width="200" />
        </Box>
      </LoginSection>
    </LoginMain>
  )
}

export default LoginComponent
